<template>
  <div class="index_container meet meet-apply">
    <!-- <ItemScroll ref="ItemScroll" :isClearMove="false"> -->
    <div class="meet-apply-main">
      <div class="meet_info_title">
        {{
          !data.id
            ? "我要发贴"
            : data.id && data.audit_status == 20 && data.meet_type == 10
            ? "会议信息确认"
            : "信息修改"
        }}
      </div>
      <div class="meet_info_block">
        <div class="meet_info_block_title">
          <el-icon>
            <ChatLineSquare />
          </el-icon>
          基本信息
        </div>
        <div class="meet_info_row">
          <div class="meet_info_row_key">
            <span class="global_tag_require">*</span>标题：
          </div>
          <div class="meet_info_row_value">
            <el-input
              v-model="data.title"
              autosize
              @input="
                () => {
                  initScreen('title');
                }
              "
              type="textarea"
              placeholder="请输入标题"
            />
            <div class="global_container_mask" v-if="getModifyStatus()"></div>
          </div>
        </div>
        <div class="meet_info_row">
          <div
            class="meet_info_row_key"
            style="height: 1.8rem; line-height: 1.8rem"
          >
            <span class="global_tag_require"></span>正文：
          </div>
          <div class="meet_info_row_value">
            <el-input
              v-model="data.content"
              @input="
                () => {
                  initScreen('content');
                }
              "
              :autosize="{ minRows: 1 }"
              type="textarea"
              :placeholder="getPlaceholder('content', '请输入正文')"
            />
            <div class="global_container_mask" v-if="getModifyStatus()"></div>
          </div>
        </div>
        <!-- 发帖时不必传 确认会议信息时必传 -->
        <div
          class="meet_info_row"
          v-if="data.audit_status >= 20 && data.meet_type == 10"
        >
          <div class="meet_info_row_key">
            <span class="global_tag_require"></span>上传课件/病例：
          </div>
          <div
            class="meet_info_row_value meet_info_upload"
            @click="selectFile('file_attachments_url')"
          >
            <div class="files_item right-show" v-if="data.file_attachments_url">
              {{ getFileName("file_attachments_url") }}
            </div>
            <div class="colorRed upload right-show">
              {{ data.file_attachments_url ? "重新上传" : "点击上传" }}
            </div>
            <div class="meet_info_row_key_info">
              注：不上传课件会议室将预约不成功
            </div>
          </div>
        </div>
        <div
          class="meet_info_row"
          v-if="data.audit_status >= 20 && data.meet_type == 10"
        >
          <div class="meet_info_row_key">
            <span class="global_tag_require"></span>讲题：
          </div>
          <div class="meet_info_row_value">
            <el-input
              v-model="data.meet_topics"
              @input="
                () => {
                  initScreen('meet_topics');
                }
              "
              :autosize="{ minRows: 1 }"
              type="textarea"
              :placeholder="getPlaceholder('meet_topics', '请输入讲题')"
            />
          </div>
        </div>
        <div class="meet_info_row">
          <div class="meet_info_row_key">
            <span class="global_tag_require">*</span>疾病类型：
          </div>
          <div class="meet_info_row_value">
            <el-select
              v-model="data.diseases_top_type"
              placeholder="请选择疾病类型"
              @change="changeDisease"
            >
              <el-option
                v-for="(item, index) in $config.types.disease"
                :key="index"
                :label="index"
                :value="index"
              >
              </el-option>
            </el-select>
            <!-- <div class="global_container_mask" v-if="getModifyStatus()"></div> -->
          </div>
        </div>
        <div class="meet_info_row" v-if="data.diseases_top_type == '其他'">
          <div class="meet_info_row_key"></div>
          <div class="meet_info_row_value">
            <el-select v-model="data.diseases" placeholder="请选择疾病类型">
              <el-option
                v-for="(item, index) in $config.types.disease[
                  data.diseases_top_type
                ] || []"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <!-- <div class="global_container_mask" v-if="getModifyStatus()"></div> -->
          </div>
        </div>
        <!-- <div class="meet_info_row" v-if="data.file_attachments_url">
          <div class="meet_info_row_key">
            <span class="global_tag_require">*</span>附件是否展示：
          </div>
          <div class="meet_info_row_value">
            <el-radio-group v-model="data.show_attachments" class="ml-4">
              <el-radio
                v-for="item in [...$config.types.status].reverse()"
                :key="item"
                :value="item.value"
                :label="item.value"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </div> -->
        <!-- <div class="meet_info_row">
            <div class="meet_info_row_key">
              <span class="global_tag_require"></span>上传视频：
            </div>
            <div
              class="meet_info_row_value meet_info_upload"
              @click="selectFile('file_class_url')"
            >
              <div
                class="colorRed upload right-show"
                v-if="!data.file_class_url"
              >
                点击上传
              </div>
              <p class="files_item right-show" v-else>
                {{ getFileName("file_class_url") }}
              </p>
              <div
                class="colorRed upload right-show meet_info_reupload"
                v-if="data.file_class_url"
              >
                重新上传
              </div>
            </div>
          </div> -->

        <div class="meet_info_row">
          <div class="meet_info_row_key">
            <span class="global_tag_require">*</span>是否有典型病例：
          </div>
          <div class="meet_info_row_value">
            <el-radio-group
              v-model="data.patientType"
              class="ml-4"
              @change="changeType"
            >
              <el-radio
                v-for="item in [...$config.types.status].reverse()"
                :key="item"
                :value="item.value"
                :label="item.value"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
            <div class="global_container_mask" v-if="getModifyStatus()"></div>
          </div>
        </div>
      </div>
      <div class="meet_info_block">
        <div class="meet_patient" v-if="data.has_diseases == 1">
          <div class="meet_info_block_title">
            <el-icon>
              <ChatLineSquare />
            </el-icon>
            典型病例
          </div>

          <div class="meet_info_row">
            <div class="meet_info_row_key">
              <span class="global_tag_require">*</span>省份：
            </div>
            <div class="meet_info_row_value">
              <el-select
                v-model="data.province"
                placeholder="请选择省份"
                @change="changeProvince()"
              >
                <el-option
                  v-for="(item, index) in provinces"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <div class="global_container_mask" v-if="getModifyStatus()"></div>
            </div>
          </div>
          <div class="meet_info_row">
            <div class="meet_info_row_key">
              <span class="global_tag_require">*</span>城市：
            </div>
            <div class="meet_info_row_value">
              <el-select
                v-model="data.city"
                placeholder="请选择城市"
                @change="changeCity"
              >
                <el-option
                  v-for="(item, index) in citys"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <div class="global_container_mask" v-if="getModifyStatus()"></div>
            </div>
          </div>

          <div class="meet_info_row">
            <div class="meet_info_row_key">
              <span class="global_tag_require">*</span>医院：
            </div>
            <div class="meet_info_row_value">
              <el-select
                v-model="data.hospital"
                placeholder="请选择医院"
                @change="changeHospital"
                :reserve-keyword="false"
              >
                <el-option
                  v-for="(item, index) in config.hospitals"
                  :key="index"
                  :label="item.hospital"
                  :value="item.hospital"
                >
                </el-option>
              </el-select>
              <div class="global_container_mask" v-if="getModifyStatus()"></div>
            </div>
          </div>
          <div class="meet_info_row" v-if="data.hospital == '其他'">
            <div class="meet_info_row_key">
              <span class="global_tag_require"></span>
            </div>
            <div class="meet_info_row_value">
              <el-input v-model="data.tempHospital" placeholder="请输入医院">
              </el-input>
              <div class="global_container_mask" v-if="getModifyStatus()"></div>
            </div>
          </div>
          <div class="meet_info_row">
            <div class="meet_info_row_key">
              <span class="global_tag_require">*</span>性别：
            </div>
            <div class="meet_info_row_value">
              <el-radio-group v-model="data.sex" class="ml-4">
                <el-radio
                  v-for="(item, index) in config.sexs"
                  :key="item"
                  :label="index"
                  >{{ item }}</el-radio
                >
              </el-radio-group>
              <div class="global_container_mask" v-if="getModifyStatus()"></div>
            </div>
          </div>
          <div class="meet_info_row">
            <div class="meet_info_row_key">
              <span class="global_tag_require">*</span>年龄：
            </div>
            <div class="meet_info_row_value">
              <el-input
                type="number"
                v-model="data.age"
                placeholder="请输入年龄"
                @input="inputField"
              />
            </div>
            <div class="global_container_mask" v-if="getModifyStatus()"></div>
          </div>

          <div class="meet_info_row">
            <div class="meet_info_row_key">
              <span class="global_tag_require">*</span>PS评分：
            </div>
            <div class="meet_info_row_value">
              <el-select v-model="data.ps_score" placeholder="请选择PS评分">
                <el-option
                  v-for="(item, index) in config.scores"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <div class="global_container_mask" v-if="getModifyStatus()"></div>
            </div>
          </div>
          <div class="meet_info_row">
            <div class="meet_info_row_key">
              <span class="global_tag_require">*</span>治疗线数：
            </div>
            <div class="meet_info_row_value">
              <el-select
                v-model="data.treatment_lines"
                placeholder="请选择治疗线数"
              >
                <el-option
                  v-for="(item, index) in config.treatments"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <div class="global_container_mask" v-if="getModifyStatus()"></div>
            </div>
          </div>
          <div class="meet_info_row">
            <div class="meet_info_row_key">
              <span class="global_tag_require">*</span>当前PD-1用药周期：
            </div>
            <div class="meet_info_row_value">
              <el-select
                v-model="data.current_pd1_cycle"
                placeholder="请选择当前PD-1用药周期"
              >
                <el-option
                  v-for="(item, index) in config.medicationCycles"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <div class="global_container_mask" v-if="getModifyStatus()"></div>
            </div>
          </div>
          <div class="meet_info_row">
            <div class="meet_info_row_key">
              <span class="global_tag_require">*</span>历史联合治疗：
            </div>
            <div class="meet_info_row_value">
              <el-checkbox-group v-model="data.history_therapy">
                <el-checkbox
                  v-for="(item, index) in config.historyTreatments"
                  :key="index"
                  :label="item"
                  :value="item"
                />
              </el-checkbox-group>
              <div class="global_container_mask" v-if="getModifyStatus()"></div>
            </div>
          </div>
          <div class="meet_info_row">
            <div class="meet_info_row_key">
              <span class="global_tag_require">*</span>疗效评估：
            </div>
            <div class="meet_info_row_value">
              <el-select
                v-model="data.efficacy_evaluation"
                placeholder="请选择疗效评估"
              >
                <el-option
                  v-for="(item, index) in config.curativeEffects"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <div class="global_container_mask" v-if="getModifyStatus()"></div>
            </div>
          </div>
          <div class="meet_info_row">
            <div class="meet_info_row_key">
              <span class="global_tag_require">*</span>irAE类型：
            </div>
            <div class="meet_info_row_value">
              <el-select
                v-model="data.irae_top_type"
                placeholder="请选择irAE类型"
                @change="changeIRAE"
              >
                <el-option
                  v-for="(item, index) in $config.types.irAE"
                  :key="index"
                  :label="index"
                  :value="index"
                >
                </el-option>
              </el-select>
              <div class="global_container_mask" v-if="getModifyStatus()"></div>
            </div>
          </div>
          <div class="meet_info_row">
            <div class="meet_info_row_key"></div>
            <div class="meet_info_row_value">
              <el-select
                v-model="data.irae_type"
                :visible-change="changeSelectPoper"
                placeholder="请选择irAE类型"
              >
                <el-option
                  v-for="(item, index) in $config.types.irAE[
                    data.irae_top_type
                  ] || []"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <div class="global_container_mask" v-if="getModifyStatus()"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="meet_info_block">
        <div class="meet_info_block_title">
          <el-icon>
            <ChatLineSquare />
          </el-icon>
          会议信息
        </div>
        <div class="meet_info_row">
          <div class="meet_info_row_key">
            <span class="global_tag_require">*</span
            >{{ data.meet_type == 10 ? "正式会议日期：" : "预定会议日期：" }}
          </div>
          <div class="meet_info_row_value">
            <el-date-picker
              v-model="meetInfo.date"
              value-format="YYYY-MM-DD"
              @change="changeDate()"
              @panelChange="handlePanelChange"
              :editable="false"
              :disabled-date="verityDate"
              type="date"
              :placeholder="`请选择${
                data.meet_type == 10 ? '正式会议日期' : '预定会议日期'
              }`"
            >
              <template #default="cell">
                <div class="cell" :class="{ current: cell.isCurrent }">
                  <span class="text">{{ cell.text }}</span>
                  <span v-if="isHoliday(cell)" class="holiday">已满</span>
                </div>
              </template>
            </el-date-picker>
          </div>
        </div>
        <div class="meet_info_row">
          <div class="meet_info_row_key">
            <span class="global_tag_require">*</span
            >{{ data.meet_type == 10 ? "正式会议时间：" : "预定会议时间：" }}
          </div>
          <div class="meet_info_row_value meet_info_select_hour">
            <el-select
              v-model="meetInfo.hour"
              placeholder="请选择小时"
              @change="changeHour()"
            >
              <el-option
                v-for="(item, index) in getHours"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="meetInfo.minute"
              placeholder="请选择分钟"
              @change="changeHour()"
            >
              <el-option
                v-for="(item, index) in getMinutes"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="meet_info_row" v-if="false">
          <div class="meet_info_row_key">
            <span class="global_tag_require">*</span>会前沟通会日期：
          </div>
          <div class="meet_info_row_value">
            <el-date-picker
              v-model="meetInfo.testDate"
              value-format="YYYY-MM-DD"
              @change="changeTestDate()"
              :disabled-date="verityTestDate"
              :editable="false"
              placeholder="请选择会前沟通会日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="meet_info_row" v-if="false">
          <div class="meet_info_row_key">
            <span class="global_tag_require">*</span>会前沟通会时间：
          </div>
          <div class="meet_info_row_value meet_info_select_hour">
            <el-select
              v-model="meetInfo.testHour"
              placeholder="请选择小时"
              @change="changeTestHour"
            >
              <el-option
                v-for="(item, index) in getTestHours"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-select v-model="meetInfo.testMinute" placeholder="请选择分钟">
              <el-option
                v-for="(item, index) in getTestMinutes"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="meet-apply_desc" v-if="data.meet_type != 10">
        *预定会议时间仅为参考，后续会议团队组建成功后，可修改为最终确认的正式会议时间
      </div>
      <div class="meet_info_block btn_group">
        <CustomButton
          class="save"
          size="small"
          type="info"
          @click="doCommit(10)"
          v-if="!data.id"
        >
          <span> 保存(暂不发布) </span>
        </CustomButton>
        <CustomButton
          class="save"
          size="small"
          type="info"
          @click="doCommit(30)"
          v-if="data.id"
        >
          <span> 暂不修改 </span>
        </CustomButton>
        <CustomButton
          class="submit"
          size="small"
          type="primary"
          @click="doCommit(20)"
        >
          <span>
            {{
              !data.id
                ? "发布"
                : data.id && data.audit_status == 20 && data.meet_type == 10
                ? "确认并预约会议室"
                : "确认修改"
            }}
            <!-- {{
                data.audit_status == 20 && data.meet_type == 10
                  ? "确认并预约会议室"
                  : "发布"
              }} -->
          </span>
        </CustomButton>
      </div>
    </div>
    <!-- </ItemScroll> -->
    <input
      type="file"
      v-show="false"
      v-if="resetFile"
      @change="changeFile"
      ref="selectFile"
    />
    <Popup
      :msg="config.msg"
      @doSure="doSure"
      @doClose="doClose"
      v-if="showPopup"
    >
      <div class="popup_content">{{ config.msg.content }}</div>
    </Popup>
    <popup
      :msg="config.msgHasDiseases"
      @doSure="doSureHasDiseases"
      @doClose="doCloseHasDiseases"
      v-if="config.msgHasDiseases.isShow"
    >
      <div class="popup_content">
        {{ config.msgHasDiseases.content }}
      </div></popup
    >
  </div>
</template>
<script>
import { Close, ChatLineSquare, UploadFilled } from "@element-plus/icons";
import Popup from "../../unit/Popup.vue";
import CustomButton from "../../unit/CustomButton.vue";
import ItemScroll from "../../unit/ItemScroll.vue";
import { ElDatePicker, ElIcon, selectKey } from "element-plus";
import uploads from "@/common/uploads";
import PopopInstance from "@/components/unit/PopupInstance";
export default {
  name: "index",
  components: {
    Close,
    Popup,
    ElDatePicker,
    ChatLineSquare,
    ElIcon,
    UploadFilled,
    CustomButton,
    ItemScroll,
  },
  data() {
    return {
      meetNumWarning: [],
      search: "",
      resetFile: true,
      data: {
        id: 0,
        post_type: 0, // 组队模式，默认是自由组队
        has_diseases: 0, // 是否有典型病例
        patientType: 0, // 是否有典型病例
        title: "", //标题
        post_tag: 0, // 保存状态 10 保存 20发布
        content: "", // 正文
        diseases_top_type: "", // 疾病大类类型
        diseases: "", // 疾病类型
        province: "", // 省份
        city: "", // 城市
        hospital: "", // 医院
        sex: 0, // 性别 0 隐私 1 男 2 女
        age: 0, // 年龄
        ps_score: "", // PS评分
        treatment_lines: "", // 治疗线数
        current_pd1_cycle: "", // PD1周期
        history_therapy: [], // 历史联合治疗
        efficacy_evaluation: "", // 疗效评估
        irae_top_type: "", // irae大类类型
        irae_type: "", // irae类型
        tempHospital: "", // 其他医院
        file_attachments_url: "", // 附件
        // show_attachments: 0, // 附件展示
        file_class_url: "", // 课件链接
        meet_start_time: "", // 会议开始时间
        meet_test_time: "", // 测试会议开始时间
      },

      config: {
        hospitals: [],
        upload: {
          file_attachments_url: "",
          file_class_url: "mkv|mp4",
        },
        msg: {
          isShow: false,
          title: "温馨提示",
          align: "center",
          content:
            "温善提示:您发布的定向邀请制帖子，需要邀请专家回帖，请及时邀请相关专家",
          btngroup: { close: "稍后邀请", sure: "现在去邀请" },
        },
        msgHasDiseases: {
          isShow: false,
          title: "温馨提示",
          align: "center",
          content:
            "选择【否】会清空下方典型病例数据\n\n请再次确认 按钮【请确认】",
          btngroup: { close: "取消", sure: "确认" },
        },
        confirmNoFreeMeetInfo: {
          title: "温馨提示",
          html: `<div class="popup-content-main">该帖还有一位专家暂未同意邀请，若您此时确认会议信息，当前邀请将失效，您可在会议室预约成功之后再次邀请。请确认是否确认会议信息
              </div>`,
          btngroup: { sure: "确认", close: "取消" },
          align: "center",
        },
        confirmFreeMeetInfo: {
          title: "温馨提示",
          html: `<div class="popup-content-main">请确认是否现在确认会议信息，确认会议信息后，第3个讨论嘉宾位将不再支持公开征集，请您再次确认</div>`,
          btngroup: { sure: "确认", close: "取消" },
          align: "center",
        },
        holidayNotice: {
          title: "温馨提示",
          html: `<div class="popup-content-main">很抱歉，您无法预约当前时间。
          <div>春节期间系统休整时间为：2月09日14:00-2月18日10:00，请您合理安排时间。<div>骨转百讯项目团队祝您新年快乐！</div><div></div>`,
          btngroup: { sure: "确认" },
          align: "center",
        },
        teamTypes: this.$config.teamTypes,
        scores: this.$config.scores,
        sexs: this.$config.sexs,
        treatments: this.$config.treatments,
        medicationCycles: this.$config.medicationCycles,
        historyTreatments: this.$config.historyTreatments,
        curativeEffects: this.$config.curativeEffects,
        irAEtypes: this.$config.irAEtypes,
        citys: [],
        time: {
          start: 10,
          end: 20,
          day: 86400 * 1000,
          space: 4,
          audit_status: 0,
          canSelectDay: 3, // 允许几天内
          disadbled: [], //不允许30的时间
        },
      },
      selectKey: "",
      meetInfo: {
        date: "",
        hour: "",
        minute: "",
        testDate: "",
        testHour: "",
        testMinute: "",
      },
      loading: false,
      showPopup: false,
      audit_status: "",
      timer: {
        updateTime: 0, // 更新时间
        space: 100, // 1.5秒请求
        instance: null,
      },
    };
  },
  computed: {
    provinces() {
      return this.config.citys.map((item) => item.name);
    },
    citys() {
      if (!this.data.province) return [];
      let index = this.provinces.indexOf(this.data.province);
      if (index == -1) return [];
      return this.config.citys[index].sub.map((item) => item.name);
    },
    getHours() {
      if (!this.meetInfo.date) return [];
      let startTime =
        Date.now() + this.config.time.day * this.config.time.canSelectDay;
      if (startTime < this.$tools.getTime(this.meetInfo.date)) {
        return this.getHourList(this.config.time.start, this.config.time.end);
      } else {
        let hour = Number(this.$tools.getDate("", "hh"));
        hour = hour < this.config.time.start ? this.config.time.start : hour;
        // hour = hour > this.config.time.end ? this.config.time.end : hour
        return this.getHourList(hour, this.config.time.end);
      }
    },
    getMinutes() {
      if (this.config.time.disadbled.includes(this.meetInfo.hour))
        return ["00"];
      return ["00", "30"];
    },
    getTestHours() {
      if (!this.mergetTime()) return [];
      if (!this.meetInfo.testDate) return [];
      let startTime = this.calcNextHour(this.$tools.getTime());
      let startDate = this.$tools.getDate(startTime);
      let endTime = this.calcPrepHour(this.mergetTime());
      let endDate = this.$tools.getDate(endTime);
      let startHour = Number(this.$tools.getDate(startTime, "hh"));
      let endHour = Number(this.$tools.getDate(endTime, "hh"));
      // 日期一样
      if (
        this.meetInfo.testDate == startDate &&
        this.meetInfo.testDate == endDate
      ) {
        if (startTime < endTime) return [];
        else {
          let startHour = Number(this.$tools.getDate(startTime, "hh"));
          let endHour = Number(this.$tools.getDate(endTime, "hh"));
          return this.getHourList(startHour, endHour);
        }
        // 开始日期一样
      } else if (this.meetInfo.testDate == startDate) {
        return this.getHourList(startHour, this.config.time.end);
      } else if (this.meetInfo.testDate == endDate) {
        return this.getHourList(this.config.time.start, endHour);
      } else {
        return this.getHourList(this.config.time.start, this.config.time.end);
      }
      return [];
    },
    getTestMinutes() {
      if (this.config.time.disadbled.includes(this.meetInfo.testHour))
        return ["00"];
      return ["00", "30"];
    },
    getPlaceholder() {
      return (key, content) => {
        if (this.getModifyStatus() && !this.data[key]) {
          return "暂无";
        } else return content;
      };
    },
  },
  async created() {
    this.config.citys = await this.$tools.getCityConfig();
    if (this.$route.query.id) {
      this.data.id = this.$route.query.id;
      this.loadData();
    } else {
      this.getMeetNumWarning();
    }
  },
  mounted() {},
  methods: {
    // getPlaceholder(key, content) {
    //   if (this.getModifyStatus()) {
    //     if (!this.data[key]) content = "暂无";
    //   } else return content;
    //   return content;
    // },
    getModifyStatus() {
      // return false;
      return (
        (this.data.audit_status == 20 || this.data.audit_status >= 40) &&
        this.data.post_tag == 20
      );
    },
    inputField(e) {
      var reg = /^(1|([1-9]\d{0,1})|120)$/;
      if (!reg.test(e)) {
        this.data["age"] = "";
      }
    },

    loadData() {
      let url = this.$tools.getURL(this.$urls.live.detail, this.$route.query);
      this.$axios
        .get(url)
        .then((res) => {
          let data = res.data;
          data.history_therapy = (data.history_therapy || "").split(",");
          this.data = { ...this.data, ...data };
          // if (this.data.meet_type == 10) {
          //   this.config.time.canSelectDay = 1;
          // }
          this.data.patientType = this.data.has_diseases;
          this.initData();
        })
        .catch((err) => {});
    },
    initData() {
      if (this.data.meet_start_time) {
        this.meetInfo.date = this.$tools.getDate(this.data.meet_start_time);
        this.meetInfo.hour = this.$tools.getDate(
          this.data.meet_start_time,
          "hh"
        );
        this.meetInfo.minute = this.$tools.getDate(
          this.data.meet_start_time,
          "mm"
        );
        this.meetInfo.testDate = this.$tools.getDate(this.data.meet_test_time);
        this.meetInfo.testHour = this.$tools.getDate(
          this.data.meet_test_time,
          "hh"
        );
        this.meetInfo.testMinute = this.$tools.getDate(
          this.data.meet_test_time,
          "mm"
        );
      }
      this.loadHospital(true);
      setTimeout(() => {
        this.initScroll();
      }, 200);
      this.getMeetNumWarning();
    },
    initTimer() {
      if (this.timer.instance) {
        clearTimeout(this.timer.instance);
        this.timer.instance = null;
      }
      if (Date.now() - this.timer.updateTime > this.timer.space) {
        this.timer.updateTime = 0;
        this.initScroll();
        return;
      }
      setTimeout(() => {
        this.initTimer();
      }, 500);
    },

    initScreen(type) {
      if (type) {
        if (this.data[type].charAt(0) === " ") {
          this.data[type] = this.data[type].trimStart();
        }
      }
      if (this.timer.updateTime > 0) {
        this.timer.updateTime = Date.now();
      } else {
        this.timer.updateTime = Date.now();
        this.initTimer();
      }
    },
    changeHospital() {
      if (this.data.hospital != "其他")
        this.data.tempHospital = this.data.hospital;
      else this.data.tempHospital = "";
    },
    loadHospital(status) {
      let data = {
        provine: this.data.province,
        city: this.data.city,
      };
      let url = this.$tools.getURL(this.$urls.all.hospital, data);
      this.$axios.get(url).then((res) => {
        if (status) {
          this.data.tempHospital = this.data.hospital;
          if (!res.data.find((x) => x.hospital == this.data.hospital)) {
            this.data.hospital = "其他";
          }
        }
        this.config.hospitals = [...res.data, { hospital: "其他" }];
      });
    },

    selectFile(key) {
      this.selectKey = key;
      this.$refs.selectFile.click();
    },
    getHourList(start, end) {
      if (start > end) return [];
      return new Array(end - start + 1)
        .fill(start)
        .map((x, index) => x + index);
    },
    verityDate(date) {
      //8.31号之后不能选择
      if (
        this.$tools.getTime(this.$tools.getDate(date)) >
        this.$tools.getTime(this.$tools.getDate("2024-08-31"))
      )
        return true;

      if (
        this.meetNumWarning.includes(this.$tools.getDate(date, "yyyy-MM-dd"))
      ) {
        return true;
      }

      let hour = Number(this.$tools.getDate(null, "hh"));
      return hour > this.config.time.end
        ? this.$tools.getTime(date) <=
            Date.now() + 86400 * 1000 * this.config.time.canSelectDay
        : this.$tools.getTime(date) <=
            Date.now() +
              this.config.time.day * (this.config.time.canSelectDay - 1);
    },
    // 会前沟通会时间：可以改，需要满足：
    // 1，正式会议4个工作小时前
    // 2，当前时间，4个工作小时后
    verityTestDate(date) {
      if (!this.mergetTime()) return true;
      let startTime = this.calcNextHour(this.$tools.getTime());
      startTime = startTime - (startTime % this.config.time.day);
      let startDate = this.$tools.getTime(this.$tools.getDate(startTime));
      let endTime = this.calcPrepHour(this.mergetTime());
      endTime = endTime - (endTime % this.config.time.day);
      let endDate = this.$tools.getTime(this.$tools.getDate(endTime));
      let now = this.$tools.getTime(date);
      return !(startDate <= now && endDate >= now);
    },
    calcNextHour(time) {
      let hour = Number(this.$tools.getDate(time, "hh"));
      let startTime = this.$tools.getTime(this.$tools.getDate(time));
      // 在开始时间之前的
      if (hour < this.config.time.start) {
        return (
          startTime +
          (this.config.time.start + this.config.time.space) * 60 * 60 * 1000
        );
      }
      // 时间不够，需要借时间的
      else if (hour > this.config.time.end - this.config.time.space) {
        let space = hour - this.config.time.end;
        space = this.config.time.space + (space < 0 ? space : 0);
        return (
          startTime +
          this.config.time.day +
          (this.config.time.start + space) * 60 * 60 * 1000
        );
      }
      // 中间时间的且时间够的
      else {
        return startTime + (hour + this.config.time.space) * 60 * 60 * 1000;
      }
    },
    calcPrepHour(time) {
      let hour = Number(this.$tools.getDate(time, "hh"));
      let startTime = this.$tools.getTime(this.$tools.getDate(time));
      //会议时间前面足够四小时的
      if (hour - this.config.time.start - this.config.time.space >= 0) {
        return startTime + (hour - this.config.time.space) * 60 * 60 * 1000;
      }
      // 时间不够，需要借时间的
      else {
        let space = hour - this.config.time.start - this.config.time.space;
        startTime =
          startTime -
          this.config.time.day +
          (this.config.time.end + space) * 60 * 60 * 1000;
        return startTime;
      }
    },
    changeSelectPoper(visible) {
      if (visible) {
        this.$nextTick(() => {
          let dom = document.querySelector(".el-select-dropdown");
          if (dom) {
            try {
              let content = dom.attributes[1].value;
              let style = {};
              let styles = content.split(/[:;]/);
              for (let index in styles) {
                if (styles[index] == "min-width") {
                  style["width"] = String(styles[Number(index) + 1]).trim();
                  break;
                }
              }
              dom.style.width = style.width;
            } catch (err) {}
          }
        });
      }
    },
    changeProvince() {
      this.data.city = "";
      this.data.hospital = "";
    },
    changeCity() {
      this.data.hospital = "";
      this.loadHospital();
    },
    changeTestDate() {
      let startTime = this.calcNextHour(this.$tools.getTime());
      let endTime = this.calcPrepHour(this.mergetTime());
      if (this.meetInfo.testHour && this.meetInfo.testMinute) {
        let time = this.mergetTestTime();
        if (
          this.$tools.getTime(time) >= startTime &&
          this.$tools.getTime(time) <= endTime
        ) {
          return;
        }
      }
      this.meetInfo.testHour = "";
      this.meetInfo.testMinute = "";
    },
    changeDate() {
      if (this.meetInfo.hour && this.meetInfo.minute) {
        let time = this.mergetTime();
        if (
          this.$tools.getTime(time) >=
          Date.now() + this.config.time.day * this.config.time.canSelectDay
        ) {
          this.calcTestTime();
          return;
        }
      }
      this.meetInfo.hour = "";
      this.meetInfo.minute = "";
      this.meetInfo.testDate = "";
      this.meetInfo.testHour = "";
      this.meetInfo.testMinute = "";
    },
    changeHour() {
      if (!this.meetInfo.minute) {
        this.meetInfo.minute = "00";
      } else if (this.config.time.disadbled.includes(this.meetInfo.hour))
        this.meetInfo.minute = "00";
      this.calcTestTime();
    },
    changeMinute() {
      this.calcTestTime();
    },
    changeTestHour() {
      if (!this.meetInfo.testMinute) {
        this.meetInfo.testMinute = "00";
      } else if (this.config.time.disadbled.includes(this.meetInfo.testHour))
        this.meetInfo.testMinute = "00";
    },
    calcTestTime() {
      let meetTime = this.mergetTime();
      let testMeetTime = this.calcPrepHour(meetTime);
      this.meetInfo.testDate = this.$tools.getDate(testMeetTime);
      this.meetInfo.testHour = this.$tools.getDate(testMeetTime, "hh");
      this.meetInfo.testMinute = this.meetInfo.minute;
    },
    mergetTime() {
      if (!(this.meetInfo.date && this.meetInfo.hour && this.meetInfo.minute))
        return "";
      return `${this.meetInfo.date} ${this.meetInfo.hour}:${this.meetInfo.minute}:00`;
    },
    mergetTestTime() {
      if (
        !(
          this.meetInfo.testDate &&
          this.meetInfo.testHour &&
          this.meetInfo.testMinute
        )
      )
        return "";
      return `${this.meetInfo.testDate} ${this.meetInfo.testHour}:${this.meetInfo.testMinute}:00`;
    },
    changeType() {
      if (this.data.patientType == 0) {
        this.config.msgHasDiseases.isShow = true;
      } else {
        this.data.has_diseases = this.data.patientType;
      }
      this.initScreen();
    },
    doCloseHasDiseases() {
      this.config.msgHasDiseases.isShow = false;
      this.data.patientType = 1;
    },
    doSureHasDiseases() {
      this.config.msgHasDiseases.isShow = false;
      this.data.has_diseases = this.data.patientType;
      let temp = {
        // diseases_top_type: "", // 疾病大类类型
        // diseases: "", // 疾病类型
        province: "", // 省份
        city: "", // 城市
        hospital: "", // 医院
        tempHospital: "", //
        sex: 0, // 性别 0 隐私 1 男 2 女
        age: 0, // 年龄
        ps_score: "", // PS评分
        treatment_lines: "", // 治疗线数
        current_pd1_cycle: "", // PD1周期
        history_therapy: [], // 历史联合治疗
        efficacy_evaluation: "", // 疗效评估
        irae_top_type: "", // irae大类类型
        irae_type: "", // irae类型
      };
      this.data = { ...this.data, ...temp };
    },
    changeIRAE() {
      this.data.irae_type = "";
    },
    changeDisease() {
      this.data.diseases = "";
    },
    initScroll(status = false) {
      this.$nextTick(() => {
        // this.$refs.ItemScroll.initScroll(true);
      });
    },
    async changeFile(event) {
      let file = event.target.files[0];
      this.resetFile = false;
      this.$tips.loading();
      this.$nextTick(() => {
        this.resetFile = true;
      });
      try {
        let res = await uploads.uploadFile(
          file,
          this.config.upload[this.selectKey],
          "files",
          "",
          { type: this.$store.state.common.userId }
        );
        this.$tips.loading().close();
        this.data[this.selectKey] = res.data[0];
        this.initScreen();
      } catch (err) {
        this.$tips.loading().close();
        // this.$tips.error({ text: "上传失败,请重新上传" });
      }
    },
    getFileName(key) {
      if (!this.data[key]) return "";
      return this.data[key].split("/").pop();
    },
    verifyData(status) {
      if (!this.data.title) {
        this.$tips.error({ text: "请输入标题" });
        return false;
      }
      if (this.data.title.length < 4) {
        this.$tips.error({ text: "请标题最少四个字" });
        return false;
      }
      if (this.data.diseases_top_type == "其他") {
        if (!this.data.diseases) {
          this.$tips.error({ text: "请选择疾病类型" });
          return false;
        }
      } else if (!this.data.diseases_top_type) {
        this.$tips.error({ text: "请选择疾病类型" });
        return false;
      }
      // if (!this.data.content) {
      //   this.$tips.error({ text: "请输入正文" });
      //   return false;
      // }
      // if (this.data.content.length <script 100) {
      //   this.$tips.error({ text: "请正文最小长度为100" });
      //   return false;
      // }
      if (status == 20) {
        // if (!this.data.file_attachments_url) {
        //   this.$tips.error({ text: "请上传附件" });
        //   return false;
        // }
        // if (!this.data.file_class_url) {
        //   this.$tips.error({ text: "请上传视频" })
        //   return false;
        // }
        if (this.data.has_diseases) {
          if (!this.data.province) {
            this.$tips.error({ text: "请选择省份" });
            return false;
          }
          if (!this.data.city) {
            this.$tips.error({ text: "请选择城市" });
            return false;
          }
          if (!this.data.hospital) {
            this.$tips.error({ text: "请选择医院" });
            return false;
          }
          if (this.data.hospital == "其他" && !this.data.tempHospital) {
            this.$tips.error({ text: "请输入医院" });
            return false;
          }
          // if (!this.data.sex) {
          //   this.$tips.error({ text: "请选择性别" });
          //   return false;
          // }
          if (!this.data.age) {
            this.$tips.error({ text: "请输入年龄" });
            return false;
          }
          if (!this.data.ps_score) {
            this.$tips.error({ text: "请选择PS评分" });
            return false;
          }
          if (!this.data.treatment_lines) {
            this.$tips.error({ text: "请选择治疗线数" });
            return false;
          }
          if (this.data.current_pd1_cycle === "") {
            this.$tips.error({ text: "请选择当前PD-1用药周期" });
            return false;
          }
          if (this.data.history_therapy.length == 0) {
            this.$tips.error({ text: "请选择历史联合治疗" });
            return false;
          }
          if (!this.data.efficacy_evaluation) {
            this.$tips.error({ text: "请选择疗效评估" });
            return false;
          }
          if (!this.data.irae_type) {
            this.$tips.error({ text: "请选择irAE类型" });
            return false;
          }
        }

        if (!this.meetInfo.date) {
          this.$tips.error({ text: "请选择正式会议日期" });
          return false;
        }
        if (!this.meetInfo.hour || !this.meetInfo.minute) {
          this.$tips.error({ text: "请选择正式会议时间" });
          return false;
        }
        // if (!this.meetInfo.testDate) {
        //   this.$tips.error({ text: "请选择会前沟通会时间" });
        //   return false;
        // }
        // if (!this.meetInfo.testHour || !this.meetInfo.testMinute) {
        //   this.$tips.error({ text: "请选择会前沟通会时间：" });
        //   return false;
        // }
      }
      return true;
    },

    formatData(status) {
      let data = { ...this.data };
      data.meet_start_time = this.mergetTime();
      data.meet_test_time = this.mergetTestTime();
      data.post_tag = status;
      data.history_therapy = data.history_therapy.join(",");
      data.current_pd1_cycle = String(data.current_pd1_cycle);
      data.hospital = data.tempHospital;
      return data;
    },
    doCommit(status) {
      // 暂不修改
      if (status == 30) {
        this.$router.push({
          path: "/myMeetList",
        });
        return;
      }
      if (!this.verifyData(status) || this.loading) return;
      let data = this.formatData(status);
      this.loading = true;
      let method, urlKey;
      if (this.data.id) {
        method = "patch";
        urlKey = "update";
      } else {
        method = "post";
        urlKey = "create";
      }

      if (status == 20 && this.data.meet_type == 10) {
        if (!this.data.file_attachments_url) {
          this.loading = false;
          this.$tips.error({ text: "请上传附件" });
          return false;
        }
        if (
          ((this.data.no_join.ROLE_TAOLUN.length == 1 &&
            this.data.post_type == 1) ||
            (this.data.ROLE_TAOLUN.length == 2 && this.data.post_type == 0)) &&
          this.data.meet_type == 10 &&
          this.data.audit_status == 20
        ) {
          let msg = this.data.post_type
            ? this.config.confirmNoFreeMeetInfo
            : this.config.confirmFreeMeetInfo;
          //指定邀请
          PopopInstance(msg)
            .then(() => {
              let load = this.$tips.loading();
              this.$axios[method](this.$urls.live[urlKey], data)
                .then((res) => {
                  setTimeout(() => {
                    load.close();
                    this.loading = false;
                  }, 1000);
                  if (res.data?.code == "4001") {
                    PopopInstance(this.config.holidayNotice).then(() => {});

                    return;
                  }
                  this.$router.push({
                    path: "/myMeetList",
                  });
                })
                .catch(() => {
                  setTimeout(() => {
                    this.loading = false;
                    load.close();
                  }, 1000);
                });
            })
            .catch(() => {
              setTimeout(() => {
                this.loading = false;
              }, 1000);
            });
          return false;
        }
      }

      let load = this.$tips.loading();
      this.$axios[method](this.$urls.live[urlKey], data)
        .then((res) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          if (data.post_tag == 10) {
            this.data.id = res.post_id;
            load.close();
            this.$tips.success({ text: "保存成功" });
          } else {
            load.close();
            if (res.data?.code == "4001") {
              PopopInstance(this.config.holidayNotice)
                .then(() => {})
                .catch(() => {});
              return;
            }
            let route =
              !this.data.id || this.data.post_tag == 10
                ? "/publish"
                : "/myMeetList";
            this.$router.push({
              path: route,
              query: {
                id: res.post_id,
              },
            });
          }
        })
        .catch(() => {
          setTimeout(() => {
            load.close();
            this.loading = false;
          }, 1000);
        });
    },
    doSure() {
      this.$router.push({ path: "/inviteDoctor" });
      this.doClose();
    },
    doClose() {
      this.showPopup = false;
    },
    getYM(now, type) {
      let month = now.getMonth() + 1; //获取当前月
      let year = now.getFullYear(); // 获取年份
      if (type == "start") {
        if (month == 1) {
          month = 12;
          year--;
        } else {
          month--;
        }
      }
      if (type == "end") {
        if (month == 12) {
          month = 1;
          year++;
        } else {
          month++;
        }
      }
      month = month < 10 ? `0${month}` : month;
      return `${year}-${month}`;
    },
    getMeetNumWarning(visible) {
      let time = "";
      if (visible) {
        time = new Date(visible);
      } else {
        time = this.meetInfo.date ? new Date(this.meetInfo.date) : new Date();
      }
      let url = this.$tools.getURL(this.$urls.live.meetNumWarning, {
        start_time: this.getYM(time, "start"),
        end_time: this.getYM(time, "end"),
      });
      // console.log("url :>> ", url);
      this.$axios
        .get(url)
        .then((res) => {
          this.meetNumWarning = res.data;
        })
        .catch((err) => {});
    },
    isHoliday({ dayjs }) {
      return this.meetNumWarning.includes(dayjs.format("YYYY-MM-DD"));
    },
    handlePanelChange(visible) {
      this.getMeetNumWarning(visible);
    },
  },
};
</script>
<style >
.index_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.meet-apply {
  background: #fff6f7 !important;
}
.meet_info_title {
  background: #e64638;
  color: #fff;
  padding: 0.75rem 0;
  text-align: center;
}

.meet_apply_main {
  flex: 1 0 0;
  padding: 0 0.5rem;
}

.meet_info_block_title {
  color: #e64638;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  margin: 6 0;
  padding: 0.7rem 0 0.2rem;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
}
.meet_info_block_title .el-icon {
  margin-right: 0.25rem;
}
.meet_info_block {
  margin: 0 0.5rem;
}

.meet .el-checkbox {
  margin-right: 0.8rem;
}

.meet_upload_main {
  display: flex;
  align-items: flex-start !important;
}

.meet_upload_block {
  flex: 1 0 0;
  padding: 0.5rem 0;
}

.meet_upload_block .el-icon {
  color: #999;
}

.meet_info_row {
  min-height: 2.5rem;
  display: flex;
  border-bottom: 1px solid #ccc;
  align-items: center;
  position: relative;
}

.meet_info_row_key {
  height: 100%;
  flex: 7.2rem 0 0;
  text-align: left;
  margin-right: 0.5rem;
  font-size: 0.8rem;
  display: flex;
}
.meet_info_row_key_info {
  display: flex;
  width: 100%;
  font-size: 0.8rem;
  color: red;
}
.meet_info_row_class {
  flex-direction: column;
  align-items: flex-start;
}
.meet_info_row_key_info:nth-child(2) {
  color: red;
}
.meet_info_row_value {
  flex: 1 0 0;
  position: relative;
}

.meet .el-textarea__inner {
  border: none;
  font-size: 0.8rem;
  background: #fff6f7 !important;
  padding: 0 !important;
}

.meet .el-select {
  width: 100%;
}

.meet .el-input__inner {
  border: none;
  font-size: 0.8rem;
  background: #fff6f7 !important;
  padding: 0;
}
.meet .el-date-editor .el-input__prefix {
  display: none;
}

.meet .upload {
  text-decoration: underline;
  margin: 0.5rem 0;
  font-size: 0.8rem;
}

.meet .right-show {
  margin-right: 1rem;
  font-size: 0.8rem;
}
.meet_info_reupload {
  text-align: left;
  margin-right: 0 !important;
}
.upload-value-item {
  padding-left: 2.52rem;
}

.meet .files_item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-break: break-all;
  margin: 0.5rem 0;
}

.meet .files_item .el-icon {
  color: #e64638;
  font-size: 1.25rem;
  font-size: 0.8rem;
}

.meet .el-checkbox__label {
  font-size: 0.8rem;
}

.meet .btn_group {
  display: flex;
  justify-content: space-around;
  margin: 1rem 0;
}

.meet .btn_group .submit {
  width: 40%;
  background: #e64638;
  border: none;
}
.meet_info_upload {
  display: flex;
  flex-direction: column;
}
.meet .btn_group .save {
  width: 40%;
}

.meet .popup_content {
  margin: 1rem 0;
}

.meet-apply {
  overflow: hidden;
  height: 100%;
  background: #fff6f7;
}

.meet-apply-main {
  flex: 1 0 0;
  overflow: auto;
}

.meet-apply input,
.meet-apply input::placeholder {
  font-size: 0.8rem;
}

.el-time-spinner__wrapper:nth-child(3) {
  display: none !important;
}

.meet_info_select_hour {
  display: flex;
}
.meet-apply .global_tag_require {
  width: 10px;
  display: block;
}
.meet-apply .meet-apply_desc {
  font-size: 0.6rem;
  margin-top: 1rem;
  color: #e64638;
  margin-left: 0.5rem;
}
.el-message {
  white-space: pre-line !important;
}
.el-scrollbar__wrap {
  /* width: 274px !important; */
}
.el-select-dropdown__item {
  white-space: pre-wrap;
  height: auto;
  padding: 0 20px !important;
}

.cell {
  height: 30px;
  padding: 3px 0;
  box-sizing: border-box;
}
.el-date-table__row .disabled .cell {
  background: #f5f7fa;
  color: #c0c4cc;
}
.cell .text {
  width: 24px;
  height: 24px;
  display: block;
  margin: 0 auto;
  line-height: 24px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
}
.cell.current .text {
  background: #e64638;
  color: #fff;
}

.cell .holiday {
  font-weight: bold;
  font-size: 10px;
  color: #e64638;
  /* background: #e64638; */
  position: absolute;
  border-radius: 50%;
  bottom: -13px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
.meet-apply .el-input__wrapper {
  background: #fff6f7;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.meet-apply .el-select .el-input.is-focus .el-input__wrapper {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.meet-apply .el-input__wrapper {
  padding-left: 0 !important;
}
.meet-apply .el-textarea__inner,
.meet-apply .el-input__inner,
.meet-apply .el-input__wrapper.is-focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>